import type {Scalars} from '~/graphql/graphql';

export function useSelectedOrganisation() {
  const user = useSofieUserWhenAuthenticated();
  const organisationId = useState<Scalars['ID']['output']>('organisation.id');

  watchEffect(() => {
    if (!organisationId.value && user.value) {
      organisationId.value = user.value.organisaties?.[0]?.id;
    }
  });

  return {
    organisationId,
    organisation: computed(() => user.value?.organisaties?.find(organisation => organisation.id === organisationId.value)),
  };
}
export function useOrganisationAccess() {
  const {organisationId} = useSelectedOrganisation();
  const user = useSofieUserWhenAuthenticated();

  return {
    isAdmin: computed(() => user.value?.organisations_admin_ids?.includes(`${organisationId.value}`)),
    isProjectManager: computed(() => user.value?.project_manager_organisation_ids?.includes(`${organisationId.value}`)),
    isMaterialManager: computed(() => user.value?.material_manager_organisation_ids?.includes(`${organisationId.value}`)),
  };
}
